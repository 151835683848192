<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">Search For Business</h1>
      <div class="flex my-3">
        <div class="">
          <button
            class="bg-gray-800 py-2 px-3 rounded-md text-xs text-white"
            @click="handleGoBack"
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <div
            class="w-full p-5 pb-10 border-2 border-gray-100 bg-white rounded-lg"
          >
            <div>
              <div class="flex flex-wrap xl:-mx-2">
                <div class="w-full xl:my-2 xl:px-2">
                  <!-- Column Content -->
                  <p class="text-xs font-bold mb-3">Enter Business Email</p>
                  <p class="text-sm font-semibold inline-block">
                    <input
                      type="text"
                      class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                      name=""
                      placeholder="jane@example.com"
                      id=""
                      v-model="businessEmail"
                      required
                    />
                  </p>
                  <button
                    class="py-3 px-6 bg-blue-800 mx-2 rounded-md font-semibold text-white text-sm"
                    @click="fetchBusiness"
                  >
                    Search
                  </button>
                  <button
                    class="py-3 px-6 bg-red-800 mx-2 rounded-md font-semibold text-white text-sm"
                    @click="clearRecord"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ul class="mt-5" v-if="filterBusinesses !== undefined">
            <li
              class="bg-blue-100 rounded-md px-5 py-5 cursor-pointer"
              @click="handleViewSelected(filterBusinesses)"
            >
              <p class="text-sm">
                {{ filterBusinesses && filterBusinesses.business_name }}
              </p>
              <p class="text-sm">
                {{ filterBusinesses && filterBusinesses.full_name }}
              </p>
              <p class="text-sm">
                {{ filterBusinesses && filterBusinesses.email }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      businessEmail: "",
      errorMssg: false,
      successMssg: false,
      promptModal: false,
    };
  },
  computed: {
    ...mapGetters(["GET_WALLET_BUSINESSES"]),
    filterBusinesses() {
      return this.GET_WALLET_BUSINESSES.data
        ? this.GET_WALLET_BUSINESSES.data
        : undefined;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.clearRecord();
  },
  methods: {
    handleViewSelected(filterBusinesses) {
      this.clearRecord();
      this.$router.push({
        // name: "NewWalletCreditingPortal",
        path: `new-wallet-crediting-portal/${filterBusinesses.business_id}/${filterBusinesses.email}/?debit=${this.$route.query.debit}`,
        // params: {
        //   id: filterBusinesses.business_id,
        //   email: filterBusinesses.email,
        //   businessName: filterBusinesses.business_name,
        //   phoneNumber: filterBusinesses.phone_number,
        // },
        query: {
          debit: this.$route.query.debit,
        },
      });
    },
    handleGoBack() {
      this.clearRecord();
      this.$router.push({
        name: "WalletCreditingPortal",
      });
    },
    async fetchBusiness() {
      if (this.businessEmail != "") {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch(
            "WALLET_CREDITING_FETCH_SELECT_BUSINESS",
            { businessEmail: this.businessEmail }
          );
          this.isLoading = false;
          console.log(res.status);
          if (res.status) {
          } else {
            this.successMssg = false;
            this.errorMssg = true;
            alert("Could not find " + this.businessEmail);
            this.isLoading = false;
          }
        } catch (error) {}
      }
    },
    clearRecord() {
      this.businessEmail = "";
      this.GET_WALLET_BUSINESSES.data = undefined;
    },
    async createAdmin() {
      let payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        state: this.selectLocation,
        role: this.assignRole,
      };
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("CREATE_ADMIN", payload);
        this.isLoading = false;
        if (res.status) {
          this.successMssg = true;
          this.firstName = "";
          this.lastName = "";
          this.email = "";
          this.selectLocation = "";
          this.assignRole = "";
        } else {
          this.successMssg = false;
          this.errorMssg = true;
        }
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
